<template>
  <v-card elevation="6" outlined height="100%">
    <v-card-text>
      <div class="text-overline">Setup by</div>
      <v-row>
        <v-col>
          <v-chip v-for="res in setup.responsibles" :key="res.responsibleId">
            <v-avatar left> <v-img :src="res.responsible.googlePicture"></v-img> </v-avatar>
            {{ res.responsible.fullname }}
          </v-chip>
        </v-col>
      </v-row>
      <div class="text-overline">Driven at</div>
      <v-row style="max-height: 300px; overflow: auto">
        <v-col v-for="day in setup.testingDays" :key="day.date">
          <v-card outlined color="info" :to="dayTo(day)" height="100%" max-width="300">
            <v-card-text
              ><b>"{{ day.title }}"</b> {{ $luxon(day.date) }} in {{ groundCasing(day.ground) }}</v-card-text
            >
          </v-card>
        </v-col>
        <v-col v-if="!setup.testingDays.length">
          <v-alert type="info">Never driven yet</v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'SetupAdvancedInfoCard',
  props: { setup: Object },
  methods: {
    groundCasing(ground) {
      return ground.charAt(0).toUpperCase() + ground.slice(1).toLowerCase()
    },
    dayTo(day) {
      return { name: 'TestingDay', params: { date: DateTime.fromISO(day.date).toISODate() } }
    }
  }
}
</script>
