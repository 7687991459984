<template>
  <div class="fill-height">
    <v-img
      :src="
        $vuetify.theme.dark ? require('@assets/dallara-gp208_white.png') : require('@assets/dallara-gp208_black.png')
      "
      height="250"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
    >
    </v-img>
    <v-container v-if="setup" class="mt-2">
      <v-row>
        <v-col cols="12" md="6" lg="4">
          <setup-info-card :setup="setup">
            <v-spacer></v-spacer>
            <v-btn v-if="isMod" @click="showDeleteDialog = true" text> Delete </v-btn>
            <v-btn :to="`/setup/flow/${this.$route.params.id}`"> Edit </v-btn>
          </setup-info-card>
        </v-col>
        <v-col cols="12" md="6" lg="8">
          <setup-advanced-info-card :setup="setup" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6" lg="4">
          <setup-ride-height-card :conf="setup.setup.rideHeightConfig">
            <template v-slot:missing-action>
              <v-btn
                color="primary"
                :to="{ name: 'SetupFlow', params: { id: $route.params.id }, hash: '#ride-height' }"
              >
                Set Ride-Height
              </v-btn>
            </template>
          </setup-ride-height-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <setup-weight-card :conf="setup.setup.weightConfig">
            <template v-slot:missing-action>
              <v-btn color="primary" :to="{ name: 'SetupFlow', params: { id: $route.params.id }, hash: '#weight' }">
                Set Weight
              </v-btn>
            </template>
          </setup-weight-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <setup-toe-card :conf="setup.setup.toeConfig">
            <template v-slot:missing-action>
              <v-btn color="primary" :to="{ name: 'SetupFlow', params: { id: $route.params.id }, hash: '#toe' }">
                Set Toe
              </v-btn>
            </template>
          </setup-toe-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <setup-camber-card :conf="setup.setup.camberConfig">
            <template v-slot:missing-action>
              <v-btn color="primary" :to="{ name: 'SetupFlow', params: { id: $route.params.id }, hash: '#camber' }">
                Set Camber
              </v-btn>
            </template>
          </setup-camber-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <setup-spring-card :conf="setup.setup.springConfig">
            <template v-slot:missing-action>
              <v-btn color="primary" :to="{ name: 'SetupFlow', params: { id: $route.params.id }, hash: '#springs' }">
                Set Spring
              </v-btn>
            </template>
          </setup-spring-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <setup-damper-card :conf="setup.setup.damperConfig">
            <template v-slot:missing-action>
              <v-btn color="primary" :to="{ name: 'SetupFlow', params: { id: $route.params.id }, hash: '#damper' }">
                Set Damper
              </v-btn>
            </template>
          </setup-damper-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <setup-tyres-card :setup="setup.setup">
            <template v-slot:missing-action>
              <v-btn color="primary" :to="{ name: 'SetupFlow', params: { id: $route.params.id }, hash: '#tyres' }">
                Set Tyres
              </v-btn>
            </template>
          </setup-tyres-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <setup-driverless-card :setup="setup.setup">
            <template v-slot:missing-action>
              <v-btn color="primary" :to="{ name: 'SetupFlow', params: { id: $route.params.id }, hash: '#driverless' }">
                Set Driverless Components
              </v-btn>
            </template>
          </setup-driverless-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else style="height: calc(100% - 250px)">
      <v-row align="center" class="fill-height">
        <v-col align="center">
          <div class="text-h3 mb-6">Setup does not exist in database yet! Please provide one!</div>
          <v-btn :to="{ name: 'Setups' }" color="primary">To Setups</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <app-delete-dialog :show="showDeleteDialog" @delete="deleteSetup" @close="showDeleteDialog = false">
      Delete a setup will also delete all dependent configurations (ride-height, weight, etc.)! Are you sure your want
      delete this setup?
    </app-delete-dialog>
  </div>
</template>

<script>
import AppDeleteDialog from '@components/app/DeleteDialog'
import SetupInfoCard from '@components/setup/InfoCard'
import SetupAdvancedInfoCard from '@components/setup/AdvancedInfoCard'
import SetupRideHeightCard from '@components/setup/ConfigRideHeightCard.vue'
import SetupWeightCard from '@components/setup/ConfigWeightCard.vue'
import SetupToeCard from '@components/setup/ConfigToeCard.vue'
import SetupCamberCard from '@components/setup/ConfigCamberCard.vue'
import SetupSpringCard from '@components/setup/ConfigSpringCard.vue'
import SetupDamperCard from '@components/setup/ConfigDamperCard.vue'
import SetupTyresCard from '@components/setup/ConfigTyresCard.vue'
import SetupDriverlessCard from '@components/setup/ConfigDriverlessCard.vue'

import { BaseSetup, DeleteBaseSetup } from '@graphql/Setups.gql'
import { UserInfo } from '@graphql/Users.gql'

export default {
  name: 'SetupPage',
  components: {
    AppDeleteDialog,
    SetupInfoCard,
    SetupAdvancedInfoCard,
    SetupRideHeightCard,
    SetupWeightCard,
    SetupToeCard,
    SetupCamberCard,
    SetupSpringCard,
    SetupDamperCard,
    SetupTyresCard,
    SetupDriverlessCard
  },
  apollo: {
    setup: {
      query: BaseSetup,
      variables: function () {
        return { where: { id: this.$route.params.id } }
      },
      update: (data) => data.baseSetup
    },
    loggedUser: { query: UserInfo }
  },
  data: () => ({
    setup: undefined,
    loggedUser: undefined,
    showDeleteDialog: false
  }),
  computed: {
    isMod() {
      if (!this.loggedUser) return false
      if (this.loggedUser.role !== 'MODERATOR' && this.loggedUser.role !== 'ADMIN') return false
      return true
    }
  },
  methods: {
    deleteSetup() {
      const variables = { where: { id: this.setup.id } }

      this.$apollo
        .mutate({ mutation: DeleteBaseSetup, variables })
        .then(() => {
          this.$router.push({ name: 'Setups' })
        })
        .catch((res) => {
          this.$store.commit('openErrorDialog', res)
        })
      this.showDeleteDialog = false
    }
  }
}
</script>
