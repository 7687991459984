<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="4" class="text-right">
        <div class="text-caption">Rear Heave</div>
        <div class="text-subtitle-1">
          {{ config.rearHeave.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
        </div>
        <div class="text-caption">Rear Roll</div>
        <div class="text-subtitle-1">
          {{ config.rearRoll.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
        </div>
      </v-col>
      <v-col cols="12" sm="4" class="text-left">
        <div class="text-caption">Front Heave</div>
        <div class="text-subtitle-1">
          {{ config.frontHeave.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
        </div>
        <div class="text-caption">Front Roll</div>
        <div class="text-subtitle-1">
          {{ config.frontRoll.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
        </div>
      </v-col>
    </v-row>
    <v-row justify="center"
      ><v-col cols="auto">
        <v-img
          :src="
            $vuetify.theme.dark
              ? require('@assets/car-top-alpha-90_white.png')
              : require('@assets/car-top-alpha-90_black.png')
          "
          width="300"
          height="150"
        ></v-img> </v-col
    ></v-row>
  </div>
</template>

<script>
export default {
  name: 'SetupConfigSpringInfo',
  props: { unit: String, config: Object }
}
</script>
