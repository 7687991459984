<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" sm="6" class="text-right">
        <div class="text-caption primary--text">Rear Heave Rebound (Zugstufe)</div>
        <div class="text-subtitle-1">
          {{ config.rearHeaveRebound.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
        </div>
        <div class="text-caption info--text">Rear Heave Bump (Druckstufe)</div>
        <div class="text-subtitle-1">
          {{ config.rearHeaveBump.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="text-left">
        <div class="text-caption primary--text">Front Heave Rebound (Zugstufe)</div>
        <div class="text-subtitle-1">
          {{ config.frontHeaveRebound.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
        </div>
        <div class="text-caption info--text">Front Heave Bump (Druckstufe)</div>
        <div class="text-subtitle-1">
          {{ config.frontHeaveBump.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
        </div>
      </v-col>
    </v-row>
    <v-row justify="center"
      ><v-col cols="auto">
        <v-img
          :src="
            $vuetify.theme.dark
              ? require('@assets/car-top-alpha-90_white.png')
              : require('@assets/car-top-alpha-90_black.png')
          "
          width="300"
          height="150"
        ></v-img> </v-col
    ></v-row>
    <v-row justify="center">
      <v-col cols="12" sm="6" class="text-right">
        <div class="text-caption primary--text">Rear Roll Rebound (Zugstufe)</div>
        <div class="text-subtitle-1">
          {{ config.rearRollRebound.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
        </div>
        <div class="text-caption info--text">Rear Roll Bump (Druckstufe)</div>
        <div class="text-subtitle-1">
          {{ config.rearRollBump.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="text-left">
        <div class="text-caption primary--text">Front Roll Rebound (Zugstufe)</div>
        <div class="text-subtitle-1">
          {{ config.frontRollRebound.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
        </div>
        <div class="text-caption info--text">Front Roll Bump (Druckstufe)</div>
        <div class="text-subtitle-1">
          {{ config.frontRollBump.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} {{ unit }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SetupConfigDamperInfo',
  props: { unit: String, config: Object }
}
</script>
