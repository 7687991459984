<template>
  <v-card height="100%">
    <v-card-title>Spring</v-card-title>
    <div v-if="conf">
      <setup-config-spring-info class="px-6" :config="conf" unit="N/mm" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot />
      </v-card-actions>
    </div>

    <div v-else>
      <v-card-text>Spring config is missing! Please provide one!</v-card-text>
      <v-card-actions>
        <slot name="missing-action" />
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import SetupConfigSpringInfo from '@/components/setup/ConfigSpringInfo.vue'

export default {
  name: 'SetupSpringCard',
  components: { SetupConfigSpringInfo },
  props: { conf: Object }
}
</script>
