<template>
  <v-card elevation="6" outlined height="100%">
    <v-card-title class="text-h5" v-text="setup.title"></v-card-title>
    <v-card-subtitle>{{ setup.car.name }} {{ setup.car.season }}</v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col>
          <div class="text-overline"><v-icon dense>mdi-clock</v-icon> Created {{ $luxon(setup.createdAt) }}</div>
        </v-col>
        <v-col>
          <div class="text-overline"><v-icon dense>mdi-wrench</v-icon> Edited {{ $luxon(lastTimeEdited) }}</div>
        </v-col>
      </v-row>
      <div class="text-body-01 text-multiline">{{ setup.notes }}</div>
    </v-card-text>
    <v-card-actions>
      <slot />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'SetupInfoCard',
  props: { setup: Object },
  computed: {
    lastTimeEdited() {
      const editedAt = this.setup.responsibles
        .map((res) => {
          return new Date(res.editedAt)
        })
        .sort()
      if (!editedAt.length) return new Date().toISOString()
      return editedAt[0].toISOString()
    }
  }
}
</script>
