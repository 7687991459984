<template>
  <v-card height="100%">
    <v-card-title>Driverless</v-card-title>
    <div>
      <v-row justify="center" class="px-6 text-left">
        <v-col cols="12">
          <v-alert v-if="setup.dvMounted" text dense type="success">Driverless components mounted</v-alert>
          <v-alert v-else text dense type="info">Driverless components not mounted</v-alert>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot />
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'SetupDriverlessCard',
  props: { setup: Object }
}
</script>
