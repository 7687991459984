<template>
  <v-card height="100%">
    <v-card-title>Ride-Height</v-card-title>
    <div v-if="conf">
      <setup-wheel-info class="px-6" :config="conf" unit="mm" />
      <setup-rake class="px-6" :config="conf" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot />
      </v-card-actions>
    </div>

    <div v-else>
      <v-card-text>Ride-Height config is missing! Please provide one!</v-card-text>
      <v-card-actions>
        <slot name="missing-action" />
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import SetupWheelInfo from '@components/setup/WheelInfo.vue'
import SetupRake from '@components/setup/ConfigRake.vue'

export default {
  name: 'SetupRideHeightCard',
  components: { SetupWheelInfo, SetupRake },
  props: { conf: Object }
}
</script>
