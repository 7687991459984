<template>
  <v-card height="100%">
    <v-card-title>Tyres</v-card-title>
    <div v-if="setup.targetTyrePressure">
      <setup-axis-info class="px-6" :config="setup.targetTyrePressure" unit="bar" />
      <v-row justify="center" class="px-6 text-left">
        <v-col cols="6" sm="4">
          <div class="text-caption">Tyre Type</div>
          <div class="text-subtitle-1">{{ setup.tyres }}</div>
        </v-col>
        <v-col cols="6" sm="4">
          <div class="text-caption">Tyre Set</div>
          <div class="text-subtitle-1">{{ setup.tyreSet }}</div>
        </v-col>
        <v-col cols="6" sm="4">
          <div class="text-caption">Tyre Age</div>
          <div class="text-subtitle-1">{{ setup.tyreAge }} km</div>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot />
      </v-card-actions>
    </div>

    <div v-else>
      <v-card-text>Tyres config is missing! Please provide one!</v-card-text>
      <v-card-actions>
        <slot name="missing-action" />
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import SetupAxisInfo from '@components/setup/AxisInfo.vue'

export default {
  name: 'SetupTyresCard',
  components: { SetupAxisInfo },
  props: { setup: Object }
}
</script>
