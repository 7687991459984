<template>
  <v-card height="100%">
    <v-card-title>Toe</v-card-title>
    <div v-if="conf">
      <setup-wheel-info class="px-6" :config="conf" unit="° (deg)" />
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot />
      </v-card-actions>
    </div>

    <div v-else>
      <v-card-text>Toe config is missing! Please provide one!</v-card-text>
      <v-card-actions>
        <slot name="missing-action" />
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import SetupWheelInfo from '@components/setup/WheelInfo.vue'

export default {
  name: 'SetupToeCard',
  components: { SetupWheelInfo },
  props: { conf: Object }
}
</script>
